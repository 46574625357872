<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- employee filter -->
        <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
          <template v-slot:activator="{ on }">
            <v-text-field v-on="on"
                          clearable dense hide-details autocomplete="off"
                          @input="value => employeeFilterHandler({ value, property: 'employeeOptions', resetEmployeeId: true })"
                          @click:clear="employeeFilterHandler({ value: '', property: 'employeeOptions', resetEmployeeId: true }), (employeeIdThrowAway = ''), (filters.employeeId = null)"
                          append-icon="mdi-menu-down"
                          :placeholder="translations.filterForEmployee"
                          :value="employeeIdThrowAway" />
          </template>

          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(employee, index) in employeeOptions.employees" :key="`employee-${employee.id}_${index}`" @click="selectEmployeeHandler(employee)">
                <v-list-item-content>
                  <v-list-item-title>{{ employee.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!employeeOptions.loading && employeeOptions.hasNext">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <!-- sector filter -->
        <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
          <template v-slot:activator="{ on }">
            <v-text-field v-on="on"
                          clearable dense hide-details autocomplete="off"
                          @input="value => sectorFilterHandler({ value, property: 'availableSectorOptions', resetProjectId: true })"
                          @click:clear="sectorFilterHandler({ value: '', property: 'availableSectorOptions', resetProjectId: true }), (sectorKeyCodeThrowAway = ''), (filters.sectorId = null)"
                          append-icon="mdi-menu-down"
                          :placeholder="sectorKeyCodeThrowAway ? sectorKeyCodeThrowAway : translations.filterForSector"
                          :value="sectorKeyCodeThrowAway" />
          </template>

          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(sector, index) in availableSectorOptions.sectors" :key="`project-${sector.id}_${index}`" @click="selectProjectHandler(sector)">
                <v-list-item-content>
                  <v-list-item-title>{{ sector.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>{{ sector.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!availableSectorOptions.loading && availableSectorOptions.hasNext">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onProjectIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <!-- Confirmed ClockIns filter -->
        <v-select :items="confirmedFilterItems" v-model="filters.confirmed"></v-select>

      </div>

      <!-- page header: title -->
      <div class="all-center">
        <date-header @on-date-change="val => changeDate(val.date)" :value="date" />
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <!-- confirm clock-in -->
        <div class="all-center" style="width: 20%">
          <v-btn color="main" class="white--text" :title="translations.confirmTodaysTimes" v-if="!allClockInsAreConfirmed" @click="checkCompletedClockInsForConfirm">
            <v-icon>mdi-playlist-edit</v-icon>
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-alert v-else text type="success" class="all-clockins-confirmed" :title="translations.allClockInsConfirmed" />
        </div>

        <!-- report dialog -->
        <div class="all-center" style="width: 60%">
          <report-dialog v-if="currentUserCanAllProjects" :reports="exportReports"></report-dialog>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <!-- table header -->
      <section class="grid-row pa-3 sticky-header" :style="[{ gridTemplateColumns: `200px 50px ${middleColumnWidth}` }]">
        <div class="table-header justify-start d-flex pl-6">
          <strong style="color:black">{{ mainColumn }}</strong>
          <v-icon color="black" small>{{ icons.mdiArrowUp }}</v-icon>
        </div>
        <div></div>
        <!-- Middle scrollable container -->
        <div class="grid-mid-row horizontal-scroll-bar" :style="{ maxWidth: middleColumnWidth, gridTemplateColumns: middleContainerTemplateColumns }" @scroll="syncScroll('grid-mid-row')">
          <div v-for="header in headers" :key="header.name" class="table-header">
            <strong>{{ header.title }}</strong>
            <v-icon v-if="header.sort" small @click="changeOrder(header.name)">{{ icons.mdiArrowUp }}</v-icon>
          </div>
          <div class="table-header">
            <strong>{{ translations.in }} ({{ translations.time }})</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.out }} ({{ translations.time }})</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.workMinutes }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.workHours }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(4)" class="justify-center d-flex table-header">
            <strong>{{ translations.lunchBreak }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.notes }}</strong>
          </div>
        </div>
      </section>


      <div class="page-table-custom">
        <!-- table result - loading -->
        <template v-if="apolloLoading > 0">
          <div class="apolloLoading">
            <socket></socket>
          </div>
        </template>

        <!-- table result - data -->
        <template v-else>
          <InertVirtualReportRow v-for="(clockInData, index) in clockInTableData"
                                 :key="index"
                                 :index="index"
                                 :source="clockInData"
                                 :activeMenuList="activeMenuList"
                                 :categoryProjects="[]"
                                 :possibleQuestions="possibleQuestions"
                                 :possibleActivities="possibleActivities"
                                 :possibleSectors="possibleSectors"
                                 :middleColumnWidth="middleColumnWidth"
                                 :middleContainerTemplateColumns="middleContainerTemplateColumns"
                                 :rowDialogSectorOptions="rowDialogSectorOptions"
                                 :rowProjectsOption="rowProjectsOption"
                                 :rowCategoryOptions="rowCategoryOptions"
                                 :rowDialogCategoryOptions="rowDialogCategoryOptions"
                                 :selectedDate="date"
                                 :orderBy="orderBy"
                                 :rowDialogEmployeeOptions="rowDialogEmployeeOptions"
                                 :confirmTypeBasedOnCapabilities="confirmTypeBasedOnCapabilities"
                                 @set-active-menu-list="value => (activeMenuList = value)"
                                 @new-clockin-pair-saved="(employeeName, clockInPair) => saveNewClockInPair(employeeName, clockInPair)"
                                 @clockin-pair-updated="(employeeName, clockInPair, order) => editClockInPair(employeeName, clockInPair)"
                                 @clockin-pair-deleted="(employeeName, clockInPair) => deleteClockInPair(employeeName, clockInPair)"
                                 @fetch-more-projects="value => fetchProjects(value)"
                                 @fetch-more-categories="value => fetchCategories(value)"
                                 @fetch-more-employees="value => fetchEmployees(value)"
                                 @set-project-list-filter="sectorFilterHandler"
                                 @set-project-category-list-filter="projectCategoryFilterHandler"
                                 @set-employee-list-filter="employeeFilterHandler"
                                 @refetch-project-row="refetchProjectRow">
          </InertVirtualReportRow>
        </template>
      </div>
    </v-card>


    <!-- Confirm Warning Dialog -->
    <v-dialog v-if="confirmWarningDialog" v-model="confirmWarningDialog" max-width="430px">
      <v-card>
        <v-card-title>
          <v-row class="ma-0" justify="center">
            <h4>{{ translations.warningMissingTimeStamp }}</h4>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row class="ma-0" justify="center">
            <v-col cols="6">
              <pending-svg></pending-svg>
            </v-col>
          </v-row>

          <strong>{{ translations.employeesMissingClockout }}</strong>
          <v-row class="ma-0">
            <ul>
              <li v-for="employee in incompleteClockInEmployees" :key="`incomplete-${employee.name}`" style="cursor: pointer" class="my-1">
                <v-btn @click="scrollEmployeeIndex(employee)" text small>
                  {{ employee.name }}
                </v-btn>
              </li>
            </ul>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="confirmWarningDialog = false" small text>{{ translations.close }}</v-btn>

          <v-btn @click="confirmTodaysClockIns" :loading="confirmLoading" small color="main" dark depressed>{{ translations.confirmAsIs }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmAllClockInDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ translations.saveAllClockIns }}</v-card-title>
        <v-card-text>
          <p style="font-size: 15px;">{{ translations.areYouSureYouWantToConfirmTimes }}</p>

          <div class="d-flex justify-end">
            <v-btn @click="confirmAllClockInDialog = false" small text class="mr-2">{{ translations.cancel }}</v-btn>
            <v-btn @click="confirmTodaysClockIns" small color="main" depressed :loading="confirmLoading" dark>
              {{ translations.confirm }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>


<script>
import debounce from 'lodash/debounce'
import { translations } from '@/utils/common'
import inertEmployeesQuery from '@/graphql/InertEmployees.gql'
import sectorsQuery from '@/graphql/Sectors.gql'
import activitiesQuery from '@/graphql/Activities.gql'
import { getInertQuestionsArray } from '@/utils/api/config'
import DateHeader from '@/components/DateHeader.vue'
import ReportDialog from '@/components/Report/ReportDialog.vue'
import { mapActions, mapState } from 'vuex'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import inertClockInQuery from '@/graphql/InertClockIns.gql'
import confirmInertClockInsForOneDate from '@/graphql/ConfirmInertClockInsForOneDate.gql'
import { mdiAlert, mdiArrowUp, mdiCalendar, mdiChevronDown, mdiPlus } from '@mdi/js'
import { cloneDeep } from 'lodash'
import { Socket } from 'vue-loading-spinner'
import PendingSvg from '@/assets/PendingSvg.vue'
import InertVirtualReportRow from '@/components/InertVirtualReportRow.vue'
import categoryProjectsQuery from '@/graphql/CategoryProjects.gql'
import activeProjectsAndPartsQuery from '@/graphql/ActiveProjectAndParts.gql'
import moment from 'moment/moment'

export default {
  name: 'InertReports',

  components: {
    InertVirtualReportRow,
    PendingSvg,
    ReportDialog,
    Socket,
    DateHeader
  },

  data: () => ({
    date: null,
    apolloLoading: 0,
    employeeIdThrowAway: '',
    sectorKeyCodeThrowAway: '',
    clockInTableData: [],
    incompleteClockInEmployees: [],
    possibleQuestions: [],
    possibleActivities: [],
    possibleSectors: [],
    allClockInsAreConfirmed: false,
    confirmWarningDialog: false,
    confirmLoading: false,
    confirmAllClockInDialog: false,
    activeMenuList: 'projects',
    filters: {
      dateStr: null,
      employeeId: null,
      sectorId: null,
      confirmed: null,
      orderBy: 'employee'
    },
    employeeOptions: {
      employees: [],
      page: 1,
      hasNext: true,
      employeeFilter: '',
      loading: false
    },
    rowDialogEmployeeOptions: {
      employees: [],
      page: 1,
      hasNext: true,
      employeeFilter: '',
      loading: false
    },
    availableSectorOptions: {
      sectors: [],
      page: 1,
      hasNext: true,
      sectorFilter: '',
      loading: false
    },
    rowDialogSectorOptions: {
      sectors: [],
      page: 1,
      hasNext: true,
      sectorFilter: '',
      loading: false
    },
    rowProjectsOption: {
      sectors: [],
      page: 1,
      hasNext: true,
      sectorFilter: '',
      loading: false
    },
    rowCategoryOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      sectorFilter: '',
      loading: false
    },
    rowDialogCategoryOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      sectorFilter: '',
      loading: false
    },
    orderBy: 'employee',
    headers: []
  }),

  apollo: {
    inertClockIns: {
      query: inertClockInQuery,
      loadingKey: 'apolloLoading',
      notifyOnNetworkStatusChange: true,
      variables() {
        return {
          dateStr: this.formatDate(this.date),
          employeeId: null,
          confirmed: null,
          sectorId: null,
          orderBy: this.orderBy
        }
      },
      result(result) {
        if (result.data) {
          this.clockInTableData = this.mapClockIns(result.data.inertClockIns)
          this.checkConfirmedClockIns()
        }
      },
      fetchPolicy: 'no-cache'
    },

    activeProjectAndParts: {
      query: activeProjectsAndPartsQuery,
      variables() {
        return {
          dateStr: this.formatDate(this.date)
        }
      }
    },

    activities: {
      query: activitiesQuery,
      variables() {
        return {
          fetchNonCategory: true,
          fetchCategory: false,
          pageSize: 100,
          page: 1,
          filter: ''
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content } = data.activities
        this.possibleActivities = content
      }
    },

    sectors: {
      query: sectorsQuery,
      variables() {
        return {
          fetchNonCategory: true,
          fetchCategory: false,
          pageSize: 100,
          page: 1,
          filter: ''
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content } = data.sectors
        this.possibleSectors = content
      }
    }
  },

  mixins: [MomentMixin],

  created() {
    if (this.$route.query.date) {
      this.date = this.$route.query.date
    } else {
      this.date = this.getSubtractedDate(1, 'days').substr(0, 10)
    }

    const inertQuestions = getInertQuestionsArray()
    this.possibleQuestions = inertQuestions

    this.fetchProjectOptions()
    this.fetchEmployeeOptions()
    this.headers = this.getHeaders()
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo,
      clockInsDate: state => state.clockIns.clockInsDate,
      confirmedClockIns: state => state.clockIns.confirmedClockIns,
      currentUserCanAllProjects: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'All Projects')
    }),

    icons: () => ({
      mdiPlus,
      mdiCalendar,
      mdiChevronDown,
      mdiAlert,
      mdiArrowUp
    }),

    translations: () => translations,

    confirmedFilterItems: () => [
      { text: translations.confirmedAndNot, value: null },
      { text: translations.confirmed, value: true },
      { text: translations.notConfirmed, value: false }
    ],

    exportReports() {
      return [
        {
          name: 'InertEmployeeTimeReportShort',
          icon: 'mdi-account-settings'
        }
      ]
    },

    middleColumnWidth() {
      let width = window.innerWidth
      // Remove
      //56 for side menu
      //remove 13 for padding
      //remove 265 for name and confirm
      //remove 100 for edit buttons and reference turn
      //remove 60 for actions
      width = width - 494

      //represent all of the columns inside middle column
      const midColumns = this.middleContainerTemplateColumns
        .split(' ')
        .map(str => Number(str.replace('px', '')))
        .reduce((sum, int) => sum + int, 0)

      width = width > midColumns ? midColumns : width

      return `${width}px`
    },

    mainColumn() {
      return this.translations[this.orderBy]
    },

    middleContainerTemplateColumns() {
      let columns = [
        '110px', /* sector */
        '110px', /* activity */
        '125px', /* clock in */
        '125px', /* clock out */
        '60px', /* minutes worked */
        '60px' /* total hours */
      ]

      if (this.possibleQuestions.includes(4)) columns.push('100px' /* lunch break */)

      columns.push('130px') /* note */

      return columns.toString().replace(/,/g, ' ')
    },

    confirmTypeBasedOnCapabilities() {
      const capabilities = this.userInfo?.account?.capabilities.map(cap => cap.name)
      if (capabilities?.length && capabilities.includes('Reports Admin')) {
        return this.$store.getters.clockInConfirmedTypes.ADMIN_CONFIRMED
      } else if (capabilities?.length && capabilities.includes('Reports')) {
        return this.$store.getters.clockInConfirmedTypes.SUPERVISOR_CONFIRMED
      }
      return this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        // This function will be called when any property of `filters` change
        const clockInsVariables = {
          dateStr: this.date,
          sectorId: newFilters.sectorId,
          employeeId: newFilters.employeeId,
          confirmed: newFilters.confirmed,
          orderBy: newFilters.orderBy
        }

        this.$apollo.queries.inertClockIns.refetch(clockInsVariables)
      }
    },

    orderBy() {
      this.filters.orderBy = this.orderBy
    }
  },

  methods: {
    ...mapActions(['setClockInDateAndConfirmed']),

    changeDate(date) {
      this.date = date
      this.filters.dateStr = this.date
      this.filters.orderBy = this.orderBy
      this.$router.push({ name: 'InertReports', query: { date: date } })
      this.resetScroll('grid-mid-row')
    },

    async fetchEmployeeOptions() {
      try {
        const variables = {
          showDisabled: true,
          page: 1,
          pageSize: 10,
          sort: 'name',
          order: 'asc'
        }

        const res = await this.$apollo.query({
          query: inertEmployeesQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.inertEmployees

        const employeeListItems = ['employeeOptions', 'rowDialogEmployeeOptions']

        for (const list of employeeListItems) {
          this[list].employees.push(...content)
          this[list].hasNext = hasNext
          this[list].page++
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchProjectOptions() {
      try {
        const variables = {
          filter: '',
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: 10,
          page: 1
        }

        const res = await this.$apollo.query({
          query: sectorsQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.sectors

        const projectListItems = ['availableSectorOptions', 'rowDialogSectorOptions', 'rowProjectsOption']

        for (const projectListItem of projectListItems) {
          this[projectListItem].sectors.push(...content)
          this[projectListItem].hasNext = hasNext
          this[projectListItem].page++
        }
      } catch (e) {
        console.log(e)
      }
    },

    employeeFilterHandler: debounce(function(payload) {
      const { value, property } = payload
      if (payload?.resetEmployeeId) {
        if (!value) this.filters.employeeId = null
      }

      this[property].employees = []
      this[property].page = 1
      this[property].hasNext = true
      this[property].employeeFilter = value

      this.fetchEmployees(property)
    }, 850),

    async fetchEmployees(property) {
      // employeeOptions
      this[property].loading = true
      try {
        const { page, employeeFilter } = this[property]

        const variables = {
          name: employeeFilter,
          sort: 'name',
          order: 'asc',
          pageSize: 10,
          page: page
        }

        const res = await this.$apollo.query({
          query: inertEmployeesQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.inertEmployees

        this[property].employees.push(...content)
        this[property].hasNext = hasNext
        this[property].page++
      } catch (e) {
        console.log(e)
      }
      this[property].loading = false
    },

    selectEmployeeHandler(employee) {
      this.filters.employeeId = employee.id
      this.employeeIdThrowAway = employee.name
      this.employeeOptions.employeeFilter = ''
      this.employeeFilterHandler({ value: '', property: 'employeeOptions', resetEmployeeId: false })
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchEmployees('employeeOptions')
      }
    },

    sectorFilterHandler: debounce(function(payload) {
      const { value, property } = payload

      if (payload?.resetProjectId) {
        if (!value) this.filters.sectorId = null
      }

      this[property].sectors = []
      this[property].page = 1
      this[property].hasNext = true
      this[property].sectorFilter = value

      this.fetchProjects(property)
    }, 850),

    onProjectIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchProjects('availableSectorOptions')
      }
    },

    async fetchProjects(property) {
      this[property].loading = true
      try {
        const { page, sectorFilter } = this[property]

        const variables = {
          filter: sectorFilter,
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: 10,
          page: page
        }

        const res = await this.$apollo.query({
          query: sectorsQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.sectors

        this[property].sectors.push(...content)
        this[property].hasNext = hasNext
        this[property].page++
      } catch (e) {
        console.log(e)
      }
      this[property].loading = false
    },

    selectProjectHandler(sector) {
      this.filters.sectorId = sector.id
      this.sectorKeyCodeThrowAway = sector.keyCode
      // this.availableSectorOptions.sectorFilter = project.keyCode;
      this.availableSectorOptions.sectorFilter = ''
      this.sectorFilterHandler({ value: '', property: 'availableSectorOptions', resetProjectId: false })
    },

    checkCompletedClockInsForConfirm() {
      const mappedClockIns = this.clockInTableData
        .filter(e => e?.clockIns?.length)
        .map(({ name, clockIns, id }) =>
          clockIns.map(a => ({
            ...a,
            id: id,
            employee: name,
            confirmed: true
          }))
        )
        .flat()
        .filter(e => e.clockIn && !e.clockOut)

      if (mappedClockIns.length > 0) return this.setConfirmWarning(mappedClockIns)
      this.confirmAllClockInDialog = false
      this.confirmAllClockInDialog = true
    },

    setConfirmWarning(array) {
      const employeesMissingClockOut = array.map(e => {
        return { name: e.employee, id: e.id }
      })
      this.incompleteClockInEmployees = employeesMissingClockOut

      this.confirmWarningDialog = true
    },

    async setConfirmedStatus(itemsArray) {
      const hasNonConfirmed = e => e.confirmed === false

      const mappedClockIns = this.mapClockInsArray(itemsArray)

      let allConfirmed = false

      if (mappedClockIns.length > 0) allConfirmed = !mappedClockIns.some(hasNonConfirmed)

      const payload = {
        date: this.date,
        confirmed: allConfirmed
      }

      await this.setClockInDateAndConfirmed(payload)
    },

    async confirmTodaysClockIns() {
      this.confirmLoading = true
      try {
        await this.$apollo
          .mutate({
            mutation: confirmInertClockInsForOneDate,
            variables: {
              dateStr: this.date,
              confirmed: this.confirmTypeBasedOnCapabilities
            }
          })
          .catch(error => {
            this.handleError(error)
          })
        this.$apollo.queries.inertClockIns.refetch()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      this.confirmLoading = false
      this.confirmWarningDialog = false
      this.confirmAllClockInDialog = false
    },

    syncScroll(scrollElClass) {
      const elBeingScrolled = document.getElementsByClassName(scrollElClass)[0]
      //virtual row elements
      const virtualRowEls = document.getElementsByClassName('clockin-mid-grid-row')

      for (let i = 0; i <= virtualRowEls.length; i++) {
        if (virtualRowEls[i]) virtualRowEls[i].scrollLeft = elBeingScrolled.scrollLeft
      }
    },

    getHeaders() {
      let headers = [{ title: this.translations.activity, name: 'activity', sort: false }]

      if (this.orderBy === 'employee') {
        headers.unshift({ title: this.translations.sector, name: 'sector', sort: true })
      } else {
        headers.push({ title: this.translations.employee, name: 'employee', sort: true })
      }

      return headers
    },

    changeOrder(order) {
      this.orderBy = order
      this.headers = this.getHeaders()
      this.filters.orderBy = order
    },

    mapClockIns(clockIns) {
      return clockIns.map(({ employee, clockIns, sector, workHours }) => ({
        name: employee?.name,
        id: employee?.id,
        keyCode: employee?.keyCode,
        sector: sector,
        date: this.date,
        clockIns:
          clockIns.length === 0
            ? []
            : clockIns?.map(a => ({
              ...a,
              edit: false,
              loading: false,
              openMenu: false,
              confirmed: a?.confirmed ? true : false,
              clockInTimeStamp: a?.clockIn ? moment.utc(a?.clockIn).format('HH:mm') : '',
              clockOutTimeStamp: a?.clockOut ? moment.utc(a?.clockOut).format('HH:mm') : '',
              employee: a?.employee,
              sector: a?.sector,
              activity: a?.activity,
              clockInTimeData: {
                HH: a?.clockIn ? moment.utc(a?.clockIn).format('HH') : '',
                mm: a?.clockIn ? moment.utc(a?.clockIn).format('mm') : ''
              },
              clockOutTimeData: {
                HH: a?.clockOut ? moment.utc(a?.clockOut).format('HH') : '',
                mm: a?.clockOut ? moment.utc(a?.clockOut).format('mm') : ''
              },
              referenceDate: a?.clockIn ? this.formatDate(a.clockIn) : null
            })),
        confirmed: clockIns.length > 0 && clockIns.every(ci => ci?.confirmed >= this.confirmTypeBasedOnCapabilities),
        workHours: workHours
      }))
    },

    mapClockInsArray(array) {
      const mappedClockIns = array
        .map(e => e.clockIns)
        .filter(e => e?.length)
        .flat()
        .filter(e => e?.clockIn?.id)

      return mappedClockIns
    },

    resetScroll(scrollElClass) {
      const elBeingScrolled = document.getElementsByClassName(scrollElClass)[0]
      elBeingScrolled.scrollLeft = 0
      //virtual row elements
      const virtualRowEls = document.getElementsByClassName('clockin-mid-grid-row')

      for (let i = 0; i <= virtualRowEls.length; i++) {
        if (virtualRowEls[i]) virtualRowEls[i].scrollLeft = 0
      }
    },

    projectCategoryFilterHandler: debounce(function(payload) {
      const { value, property } = payload

      this[property].projects = []
      this[property].page = 1
      this[property].hasNext = true
      this[property].sectorFilter = value

      this.fetchCategories(property)
    }, 850),

    async refetchProjectRow(sectorId) {
      const variables = cloneDeep(this.filters)
      variables.dateStr = this.date
      variables.sectorId = sectorId

      const res = await this.$apollo.query({
        query: inertClockInQuery,
        variables: variables,
        fetchPolicy: 'network-only'
      })

      let updatedProjectRow = this.mapClockIns(res.data.inertClockIns)
      let index = this.clockInTableData.findIndex(ctd => ctd.sector.id === sectorId)
      if (index !== -1) {
        this.clockInTableData.splice(index, 1, updatedProjectRow[0])
        this.checkConfirmedClockIns()
      }
    },

    saveNewClockInPair(employeeName, clockInPair) {
      if (this.formatDate(clockInPair.clockIn) == this.date) {
        this.clockInTableData.find(citd => citd.name == employeeName).clockIns.push(clockInPair)
        this.checkConfirmedClockIns()
      }
    },

    async fetchCategories(property) {
      this[property].loading = true
      try {
        const { page, sectorFilter } = this[property]

        const variables = {
          filter: sectorFilter,
          fetchNonCategory: false,
          fetchCategory: true,
          activeStatus: true,
          pageSize: 10,
          page: page
        }

        const res = await this.$apollo.query({
          query: categoryProjectsQuery,
          variables: variables,
          fetchPolicy: 'network-only'
        })

        const { content, hasNext } = res.data.categoryProjects

        this[property].projects.push(...content)
        this[property].hasNext = hasNext
        this[property].page++
      } catch (e) {
        console.log(e)
      }
      this[property].loading = false
    },

    editClockInPair(employeeName, clockInPair) {
      // change confirm back to boolean
      clockInPair.confirmed = clockInPair?.confirmed ? true : false

      const clockInTableRow = this.clockInTableData.find(citd => citd.name == employeeName)
      const index = clockInTableRow.clockIns.indexOf(clockInTableRow.clockIns.find(ci => ci.id == clockInPair.id))
      if (index == -1) {
        this.saveNewClockInPair(employeeName, clockInPair)
      } else if (this.formatDate(clockInPair.clockIn) == this.date) {
        clockInTableRow.clockIns[index] = clockInPair
      } else {
        clockInTableRow.clockIns.splice(index, 1)
      }
      this.checkConfirmedClockIns()
    },

    deleteClockInPair(employeeName, clockInPair) {
      const clockInTableRow = this.clockInTableData.find(citd => citd.name == employeeName)
      const index = clockInTableRow.clockIns.indexOf(clockInTableRow.clockIns.find(ci => ci.id == clockInPair.id))
      clockInTableRow.clockIns.splice(index, 1)
      this.checkConfirmedClockIns()
    },

    checkConfirmedClockIns() {
      // set the 'confirmed' attribute to true if all clockIns for a given employee are confirmed
      this.clockInTableData.forEach(obj => {
        // this check is needed beacuase every method returns true when the array is empty
        if (obj.clockIns.length === 0) {
          obj.confirmed = false
        } else {
          obj.confirmed = obj.clockIns.every(clockIn => clockIn.confirmed)
        }
      })

      // check if all clockIns are confirmed
      const allClockInsAreConfirmed = this.clockInTableData.every(clockIn =>
        clockIn.clockIns.length > 0 ? clockIn.confirmed : true
      )

      this.allClockInsAreConfirmed = allClockInsAreConfirmed
      this.resetScroll('grid-mid-row')
    }
  }
}
</script>


<style>
.f-12 {
  font-size: 12px;
}

.label-style {
  font-size: 13px;
}

.time-report .v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
}

.time-report .v-label {
  font-size: 10px;
}

.time-report .v-textarea textarea {
  line-height: 18px ! Important;
}

.time-report .v-textarea textarea[readonly='readonly'] {
  color: #a6a6a6;
}

.time-report:not(.v-select).v-text-field input[readonly='readonly'] {
  color: #a6a6a6;
}

.filters-container {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-column-gap: 10px;
  position: absolute;
  left: 30px;
  top: 2px;
}

.grid-row {
  padding-left: 0 !important;
  align-items: center;
  display: grid;
}

.grid-mid-row {
  padding-left: 0 !important;
  align-items: center;
  overflow-x: scroll;
  display: grid;
}

.grid-row > * {
  text-align: center;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  z-index: 1;
}

.time-report .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.table-header {
  font-size: 12px;
  color: #767676;
  padding-left: 3px;
  padding-bottom: 5px;
}

.apolloLoading {
  display: flex;
  height: 85vh;
  justify-content: center;
  align-items: center;
}

.hover {
  cursor: pointer;
}

.horizontal-scroll-bar::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

/* Track */
.horizontal-scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.horizontal-scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.horizontal-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.horizontal-scroll-bar {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.all-clockins-confirmed {
  width: 80px;
  height: 36px;
  margin: 0;
}

.all-clockins-confirmed .v-icon {
  margin-left: 12px;
  margin-top: -9px;
}
</style>
