<template>
  <v-dialog :value="isOpen" max-width="430px">
    <v-card class="pa-2">
      <v-card-title>
        <p class="ma-0" style="font-size: 1.25rem;">{{ translations.managingTimeCardFor }}:</p>
        <p class="ma-0 pl-1" style="font-size: 1.25rem;">
          {{ name }}
        </p>
      </v-card-title>
      <v-card-text>

        <!-- employee selection -->
        <div v-if="order === 'sector'" class="mb-2">
          <label>{{ translations.employee }}</label>
          <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                dense
                hide-details
                autocomplete="off"
                outlined
                height="35px"
                @input="
                  value => {
                    employeeFilterInputHandler(value);
                  }
                "
                @click:clear="clearEmployeeSearchHandler"
                :value="getEmployeeFilterValue()"
                clearable
              ></v-text-field>
            </template>

            <div style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <v-list-item v-for="(employee, index) in rowDialogEmployeeOptions.employees" :key="`employee-${employee.id}_${index}`" @click="selectEmployeeHandler(employee)">
                  <v-list-item-content>
                    <v-list-item-title>{{ employee.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!rowDialogEmployeeOptions.loading && rowDialogEmployeeOptions.hasNext">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <v-progress-circular v-intersect.quiet="onEmployeeIntersect" indeterminate color="main" size="24"></v-progress-circular>
                  </div>
                </v-list-item>

                <v-list-item v-if="!rowDialogEmployeeOptions.loading && !rowDialogEmployeeOptions.hasNext && rowDialogEmployeeOptions.employees.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>

        <!-- sector selection -->
        <div class="report-row mb-2" v-if="order !== 'sector'">
          <label>{{ translations.project }}</label>
          <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                dense
                hide-details
                autocomplete="off"
                outlined
                height="35px"
                @input="
                  value => {
                    projectFilterInputHandler(value);
                  }
                "
                @click:clear="clearSearchHandler"
                :value="getProjectFilterValue()"
                clearable
              ></v-text-field>
            </template>

            <div style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <v-list-item v-for="(sector, index) in rowDialogSectorOptions.sectors" :key="index" @click="selectSectorHandler(sector, 'sectors')">
                  <v-list-item-content>
                    <v-list-item-title>{{ sector.keyCode }}</v-list-item-title>
                    <v-list-item-subtitle>{{ sector.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!rowDialogSectorOptions.loading && rowDialogSectorOptions.hasNext">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                  </div>
                </v-list-item>

                <v-list-item v-if="!rowDialogSectorOptions.loading && !rowDialogSectorOptions.hasNext && rowDialogSectorOptions.sectors.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
          <p v-if="newClockInRequest.sector && newClockInRequest.sector.description" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
            {{ newClockInRequest.sector.description }}
          </p>
        </div>

        <!-- activity selection -->
        <div class="d-flex">
          <div class="report-row mb-2 mr-2">
            <label>{{ translations.activity }}</label>
            <v-select v-model="newClockInRequest.activity" :items="possibleActivities" item-text="keyCode" clearable dense style="font-size: 14px" outlined append-icon="" height="35px" hide-details return-object>
              <template v-slot:item="data">
                <span>{{ `${data.item.keyCode} - ${data.item.description}` }}</span>
              </template>
            </v-select>
            <p v-if="newClockInRequest.activity && newClockInRequest.activity" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
              {{ newClockInRequest.activity.description }}
            </p>
          </div>
        </div>

        <!-- clock in/out times -->
        <div class="report-row">
          <label>{{ translations.type }} </label>
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.in" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockInTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>
        <div class="mt-2 mb-4 report-row">
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.out" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockOutTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>

        <div v-if="showNextDayOutOnDialog" class="mb-2">
          <v-alert dense text type="warning" class="ma-0">{{ translations.shiftWillEndNextDay }}</v-alert>
        </div>

        <div class="mt-10 d-flex justify-end">
          <v-btn depressed color="main" :loading="newClockInLoader" :dark="!disableSubmitNewClockInClockOutButton" :disabled="disableSubmitNewClockInClockOutButton" @click="submitNewClockInClockOut">{{ translations.confirm }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { translations } from '@/utils/common'

export default {
  name: 'NewInertClockInClockOutDialog',

  props: {
    employeeName: {
      type: String
    },
    sector: {
      type: Object,
      default: () => {
      }
    },
    isOpen: {
      type: Boolean
    },
    rowDialogSectorOptions: {
      type: Object,
      default: () => {
      }
    },
    newClockInLoader: {
      type: Boolean
    },
    orderBy: {
      type: String
    },
    possibleActivities: {
      type: Array,
      default: () => []
    },
    rowDialogEmployeeOptions: {
      type: Object,
      default: () => {
      }
    }
  },

  created() {
    this.order = this.orderBy
    this.name = this.employeeName ? this.employeeName : this.sector.keyCode
    this.newClockInRequest.sector = this.order === 'sector' ? this.sector : null
  },

  data: () => ({
    newClockInRequest: {
      employee: null,
      sector: null,
      activity: null,
      clockInTimeData: {
        HH: '00',
        mm: '00'
      },
      clockOutTimeData: {
        HH: '00',
        mm: '00'
      },
      referenceDate: null,
      lunchTime: 0
    },
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    order: null
  }),

  computed: {
    translations: () => translations,

    showNextDayOutOnDialog() {
      const clockInTime = Number(this.newClockInRequest.clockInTimeData.HH)
      const clockOutTime = Number(this.newClockInRequest.clockOutTimeData.HH)

      if (clockOutTime === 0) return false
      return clockInTime > clockOutTime
    },

    disableSubmitNewClockInClockOutButton() {
      const missingProject = !this.newClockInRequest?.sector?.keyCode
      const missingEmployee = !this.newClockInRequest?.employee?.id
      const missingActivity = !this.newClockInRequest?.activity?.keyCode

      const hasClockOutTimestamp = this.newClockInRequest.clockOutTimestamp

      if (!this.rules.isTimeFormat(this.newClockInRequest.clockInTimestamp) || (this.newClockInRequest.clockOutTimestamp && !this.rules.isTimeFormat(this.newClockInRequest.clockOutTimestamp))) return true

      if (this.order === 'sector') {
        if (!missingEmployee) return false
        return true
      }

      if (!missingProject && ((this.newClockInRequest.lunchTime !== null && this.newClockInRequest.lunchTime >= 0) || this.hasLunchTime)) {
        if (this.newClockInRequest?.sector?.keyCode === '999999') return false

        if (hasClockOutTimestamp) return missingActivity

        return false
      }

      return true
    },

    isSectorSelected() {
      return !!this.newClockInRequest.sector
    }
  },

  methods: {
    projectFilterInputHandler(value) {
      if (!value) this.newClockInRequest.sector = null

      this.$emit('set-project-list-filter', { value, property: 'rowDialogSectorOptions' })
    },

    clearSearchHandler() {
      this.newClockInRequest.sector = null
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogSectorOptions' })
    },

    getProjectFilterValue() {
      return this.newClockInRequest?.sector?.keyCode ? this.newClockInRequest?.sector?.keyCode : ''
    },

    selectSectorHandler(sector, type) {
      this.newClockInRequest.sector = sector
      this.$emit('set-active-menu-list', type)

      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogSectorOptions' })
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-projects')
      }
    },

    submitNewClockInClockOut() {
      this.$emit('onSubmit', {
        newClockInRequest: this.newClockInRequest
      })
      this.newClockInRequest = {
        employee: null,
        activity: null,
        sector: null,
        clockInTimeData: {
          HH: '00',
          mm: '00'
        },
        clockOutTimeData: {
          HH: '00',
          mm: '00'
        },
        lunchTime: 60
      }
    },

    onEmployeeIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-employees')
      }
    },

    getEmployeeFilterValue() {
      return this.newClockInRequest?.employee?.name ? this.newClockInRequest?.employee?.name : ''
    },

    employeeFilterInputHandler(value) {
      if (!value) this.newClockInRequest.employee = null

      this.$emit('set-employee-list-filter', { value, property: 'rowDialogEmployeeOptions' })
    },

    selectEmployeeHandler(employee) {
      this.newClockInRequest.employee = employee
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
    },

    clearEmployeeSearchHandler() {
      this.newClockInRequest.employee = null
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })
    }
  }
}
</script>
