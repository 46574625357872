<template>
  <div>
    <main class="virt-grid-row pa-3" :style="source.confirmed ? 'background-color: whitesmoke' : 'background-color: white'">
      <v-btn v-if="computedClockIns.some(ci => ci.id > 0 && !ci.confirmed)"
             @click="confirmAllClockInPairs"
             :disabled="allClockInsAreConfirmed || source.disabled"
             x-small fab width="25px" height="25px" depressed
             style="position: absolute; left: 180px; top: 15px">
        <v-icon size="13px">{{ icons.mdiCheck }}</v-icon>
      </v-btn>

      <div style="position: absolute; right: 3px; top: 50%; transform: translateY(-50%);" class="px-3">
        <v-btn v-if="true" @click="openNewClockInHandler" x-small fab width="25px" height="25px" depressed>
          <v-icon size="13px">{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </div>

      <!-- Main column value -->
      <section class="wb-w100 f-12 pa-1 d-flex justify-space-between">
        <b>{{ mainColumn }}</b>
      </section>

      <section>
        <!-- no clockin with default project -->
        <section v-if="dataSource.clockIns.length <= 0">
          <div class="report-row">
            <p v-if="defaultProject" class="ma-0 pa-0 d-flex align-center wb-w100 f-12">
              {{ defaultProject }}
            </p>
          </div>
        </section>

        <template v-else>
          <section v-for="(clockIn, i) in computedClockIns" :key="`type_${clockIn.id}-${i}`"
                   @click="startEditingClockIn($event, clockIn)"
                   class="mb-2 clockin-row"
                   :style="[
                       !getSectorKeyCodeFromClockIn(clockIn) ? { 'background-color': '#ffcccc' } : {},
                       { gridTemplateColumns: `50px ${middleColumnWidth} 120px` }
                     ]">
            <!-- Confirmed -->
            <div class="justify-center d-flex" style="margin-top:-6px">
              <v-checkbox v-if="clockIn.id > 0" v-model="clockIn.confirmed" color="main" dense :hide-details="true" @change="value => confirmClockInPair(clockIn, value)"></v-checkbox>
              <v-icon dense v-else-if="clockIn.confirmed">{{ icons.mdiCheck }}</v-icon>
            </div>

            <!-- Sector -->
            <div class="clockin-mid-grid-row " :style="{ maxWidth: middleColumnWidth, gridTemplateColumns: middleContainerTemplateColumns }">
              <div v-if="order === 'employee'" class="report-row">
                <p v-if="!clockIn.edit" :key="forceUpdateLabel" class="ma-0 pa-0 d-flex align-center f-12 wb-w100">
                  {{ getSectorKeyCodeFromClockIn(clockIn) }}
                </p>

                <v-menu v-else
                        v-model="clockIn.openMenu"
                        :value="clockIn.openMenu" :key="forceUpdate"
                        :disabled="clockIn.confirmed || source.disabled"
                        offset-x allow-overflow max-height="400px"
                        :open-on-focus="true" :open-on-click="false"
                        :close-on-click="false" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      @click.stop="clockIn.openMenu = true"
                      :disabled="clockIn.confirmed || source.disabled"
                      height="13px"
                      class="f-12"
                      style="width: 90px;"
                      outlined
                      hide-details
                      autocomplete="off"
                      v-model="clockIn.search"
                      @blur="textBlur(clockIn)"
                      @input="value => inputHandler(value)">
                    </v-text-field>
                  </template>

                  <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">

                    <v-list dense>
                      <template v-if="possibleSectors.length > 0">
                        <v-list-item v-for="(sector,i) in possibleSectors" :key="i" @click="changeSector(clockIn, sector)">
                          <v-list-item-content>
                            <v-list-item-title>{{ sector.keyCode }}</v-list-item-title>
                            <v-list-item-subtitle>{{ sector.description }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </div>
                </v-menu>
              </div>

              <!-- Activity -->
              <div class="report-row">
                <p v-if="!clockIn.edit" class="ma-0 pa-0" style="font-size: 12px; word-wrap: break-word">
                  {{ getActivityKeyCodeFromClockIn(clockIn) }}
                </p>

                <v-select v-else-if="possibleActivities"
                          v-model="clockIn.activity"
                          :disabled="clockIn.confirmed || source.disabled"
                          return-object
                          :items="possibleActivities"
                          item-value="id" item-text="keyCode"
                          :no-data-text="translations.noPartsAvailable"
                          style="font-size: 12px; width: 90px" class="part-select"
                          append-icon="" outlined dense hide-details height="15px"
                          clearable @click:clear="clockIn.activity = null">
                  <template v-slot:item="data">
                    <small>{{ `${data.item.keyCode} - ${data.item.description}` }}</small>
                  </template>
                </v-select>
              </div>

              <!-- Employee -->
              <div v-if="order === 'sector' && clockIn.employee" class="report-row" style="font-size: 11px">
                <p class="pa-0 ma-0">{{ formatEmployeeName(clockIn.employee.name) }}</p>
              </div>

              <!-- Clock IN -->
              <div class="report-row d-flex align-center" style="height: 32px; display: flex; justify-content: center">
                <div style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockIn" label small :color="`green lighten-5`">
                      <span :class="`green--text`" class="mr-1">
                        {{ formatHHmm(clockIn.clockIn) }}
                      </span>
                    </v-chip>
                  </template>

                  <v-text-field v-else v-model="clockIn.clockInTimeStamp"
                                v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                :disabled="clockIn.confirmed || source.disabled"
                                height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                  </v-text-field>
                </div>
              </div>

              <!-- Clock OUT -->
              <div class="report-row d-flex align-center" style="height: 32px; display: flex; justify-content: center">
                <div style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockOut" label small :color="`red lighten-5`">
                      <span :class="`red--text`" class="mr-1">
                        {{ formatHHmm(clockIn.clockOut) }}
                      </span>
                    </v-chip>
                  </template>

                  <template v-if="clockIn.edit">
                    <v-text-field v-model="clockIn.clockOutTimeStamp"
                                  v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                  :disabled="clockIn.confirmed || source.disabled"
                                  height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                    </v-text-field>
                  </template>
                </div>

                <div class="d-flex justify-center align-center" style="width: 20px">
                  <template v-if="clockIn.edit">
                    <v-btn v-if="clockIn.clockOutTimeStamp" x-small :disabled="clockIn.confirmed || source.disabled" icon color="red" @click="deleteClockOutFromClockInPair(clockIn)">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </template>
                </div>
              </div>

              <!-- Minutes Worked -->
              <div class="justify-center d-flex report-row">
                  <span class="pa-0 ma-0" style="font-size: 12px;">
                    {{ clockIn.hour }}
                  </span>
              </div>

              <!-- Work Hours -->
              <div class="justify-center d-flex report-row">
                <template v-if="order !== 'sector' && i === 0">
                  <span class="pa-0 ma-0" style="font-size: 12px;">
                    {{ sourceCopy.workHours }}
                  </span>
                </template>

                <template v-else-if="order === 'sector'">
                  <span class="pa-0 ma-0" style="font-size: 12px;">
                    {{ clockIn.workHours }}
                  </span>
                </template>
              </div>

              <!-- Lunch Break -->
              <div v-if="possibleQuestions.includes(4)" class="justify-center d-flex report-row">
                <template v-if="!clockIn.edit">
                    <span class="pa-0 ma-0" style="font-size: 12px;">
                      {{ clockIn.lunchTime || clockIn.lunchTime === 0 ? getLunchText(clockIn.lunchTime) : '' }}
                    </span>
                </template>

                <template v-else>
                  <v-select v-if="(clockIn.lunchTime !== null && clockIn.lunchTime >= 0) || !hasLunchTime"
                            v-model="clockIn.lunchTime"
                            :disabled="clockIn.confirmed || source.disabled"
                            :items="lunchBreakOptions"
                            style="font-size: 12px; width: 80px; max-width: 90px" outlined hide-details append-icon="" dense height="15px">
                  </v-select>
                </template>
              </div>

              <!-- Notes -->
              <div class="report-row">
                <v-tooltip v-if="!clockIn.edit" :disabled="!clockIn.notes" bottom color="black" max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <p v-on="on" v-bind="attrs" class="pa-0 ma-0" style="font-size: 10px; line-height: 16px; text-align: center; max-height: 30px;">
                      {{ clockIn.notes }}
                    </p>
                  </template>
                  <span>{{ clockIn.notes }}</span>
                </v-tooltip>

                <v-textarea v-else v-model.trim="clockIn.notes" rows="1" class="ma-0 pa-0 clock-in-notes" outlined hide-details :disabled="clockIn.confirmed || source.disabled"></v-textarea>
              </div>
            </div>

            <!-- Actions -->
            <div style="position: absolute; right: 50px;" class="px-3" :class="[clockIn.edit ? 'justify-start' : 'justify-center']">
              <div v-if="!clockIn.loading && !source.disabled">
                <div v-if="clockIn.edit">
                  <v-btn x-small icon class="mr-2" @click="saveRow(clockIn)" :disabled="isSaveRowButtonDisabled(clockIn)">
                    <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                  </v-btn>

                  <v-btn x-small icon class="mr-2" @click.stop="onCancel(clockIn)">
                    <v-icon>{{ icons.mdiCancel }}</v-icon>
                  </v-btn>

                  <v-btn v-if="clockIn.id > 0" x-small icon @click="deleteClockInPair(clockIn)" color="red">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </div>

                <v-btn v-else-if="!clockIn.confirmed" x-small icon @click="startEditingClockIn($event, clockIn)">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                </v-btn>
              </div>
              <v-btn v-else-if="clockIn.loading" icon :loading="true"></v-btn>
            </div>
          </section>
        </template>
      </section>
    </main>
    <v-divider></v-divider>

    <!-- New Inert ClockIn Dialog -->
    <new-inert-clock-in-clock-out-dialog v-if="clockInDialog"
                                         :isOpen="clockInDialog"
                                         :employeeName="sourceCopy.name"
                                         :sector="sourceCopy.sector"
                                         :rowDialogSectorOptions="rowDialogSectorOptions"
                                         :rowDialogEmployeeOptions="rowDialogEmployeeOptions"
                                         :possibleActivities="possibleActivities"
                                         :newClockInLoader="newClockInLoader"
                                         :orderBy="orderBy"
                                         @set-project-list-filter="value => $emit('set-project-list-filter', value)"
                                         @fetch-more-projects="$emit('fetch-more-projects', 'rowDialogSectorOptions')"
                                         @fetch-more-employees="$emit('fetch-more-employees', 'rowDialogEmployeeOptions')"
                                         @onSubmit="saveNewClockInClockOut">
    </new-inert-clock-in-clock-out-dialog>
  </div>
</template>


<script>
import {
  mdiCancel,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiContentSaveOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiWeatherNight
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { translations } from '@/utils/common'
import updateOneInertClockInMutation from '@/graphql/UpdateOneInertClockIn.gql'
import deleteInertClockIn from '@/graphql/DeleteInertClockIn.gql'
import deleteInertClockOut from '@/graphql/DeleteInertClockOut.gql'
import saveInertClockInAdminRequestMutation from '@/graphql/SaveInertClockInAdminRequest.gql'
import updateInertWorkHours from '@/graphql/InertWorkHours.gql'
import NewInertClockInClockOutDialog from './NewInertClockInClockOutDialog.vue'
import { mapState } from 'vuex'
import { getLunchBreak, getLunchTimes, getResetAllowanceOnWeekend, getShowClockInTimeAlert } from '@/utils/api/config'

export default {
  name: 'InertVirtualReportRow',

  components: {
    NewInertClockInClockOutDialog
  },

  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default: () => ({})
    },
    possibleQuestions: {
      type: Array,
      default: () => []
    },
    activeMenuList: {
      type: String,
      default: 'projects'
    },
    middleColumnWidth: {
      type: String,
      required: true
    },
    middleContainerTemplateColumns: {
      type: String,
      required: true
    },
    rowDialogSectorOptions: {
      type: Object,
      default: () => {
      }
    },
    rowProjectsOption: {
      type: Object,
      default: () => {
      }
    },
    rowDialogCategoryOptions: {
      type: Object,
      default: () => {
      }
    },
    rowCategoryOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogEmployeeOptions: {
      type: Object,
      default: () => {
      }
    },
    orderBy: {
      type: String,
      default: () => {
      }
    },
    selectedDate: {
      type: String,
      default: ''
    },
    confirmTypeBasedOnCapabilities: {
      type: Number,
      default: 1
    },
    possibleActivities: {
      type: Array,
      default: () => []
    },
    possibleSectors: {
      type: Array,
      default: () => []
    }
  },

  created() {
    // A copy of the original data which is modified
    this.dataSource = cloneDeep(this.source)
    // The original data, used as source to restore dataSource to an unmodified state
    this.sourceCopy = cloneDeep(this.source)
    this.order = this.orderBy
    this.lunchBreak = getLunchBreak()
  },

  mixins: [MomentMixin],

  data: () => ({
    clockInMap: {
      showDialog: false,
      projectName: null,
      projectLocation: null,
      employeeName: null,
      employeeInLocation: null,
      employeeOutLocation: null,
      clickOn: null
    },
    dataSource: {},
    sourceCopy: {},
    forceUpdate: 0,
    forceUpdateLabel: 0,
    selectedClockInPair: {},
    clockInDialog: false,
    vanDialog: false,
    refuelDialog: false,
    securityDialog: false,
    newClockInLoader: false,
    maintenanceDialog: false,
    disableNewCreationBtn: false,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isGreaterThanZero: value => {
        return value > 0
      },
      isNotBlank: value => {
        return value && value.trim() != ''
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    changedProjectList: false,
    equipmentDialog: false,
    order: null,
    switchHourDialog: false,
    dialogType: '',
    dialogTitle: '',
    dialogTypeHours: '',
    lunchBreak: {}
  }),

  watch: {
    source: {
      deep: true,
      handler(val) {
        this.dataSource = cloneDeep(val)
        this.sourceCopy = cloneDeep(this.source)
      }
    },

    orderBy() {
      this.order = this.orderBy
    }
  },

  methods: {
    formatHHmm(timestamp) {
      return timestamp ? this.momentDate(timestamp, 'HH:mm') : null
    },

    textBlur(clockIn) {
      setTimeout(() => {
        if (this.changedProjectList) {
          this.changedProjectList = false
          return
        } else {
          clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn)
          clockIn.openMenu = false
          this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOption' })
          this.$emit('set-project-category-list-filter', { value: '', property: 'rowCategoryOptions' })
        }
      }, 400)
    },

    getLunchText(num) {
      const foundOption = this.lunchBreakOptions.find(lunch => lunch.value === num)

      return foundOption.text || ''
    },

    async deleteClockInPair(clockInPair) {
      clockInPair.loading = true

      await this.$apollo.mutate({
        mutation: deleteInertClockIn,
        variables: { clockInId: clockInPair.id }
      })

      if (this.order !== 'sector') {
        this.$emit('clockin-pair-deleted', this.source.name, clockInPair)
        await this.updateWorkHours()
      } else {
        this.refetchProjectRow(clockInPair.sectorKeyCode)
      }

      clockInPair.loading = false
      clockInPair.edit = false
    },

    getActivityKeyCodeFromClockIn(clockIn) {
      return clockIn?.activity?.keyCode || '-'
    },

    changeSector(clockIn, value) {
      clockIn.sector = value?.sector ? value.sector : value
      clockIn.search = value.keyCode
      clockIn.openMenu = false
    },

    getSectorKeyCodeFromClockIn(clockIn) {
      if (clockIn?.sector?.keyCode) return clockIn.sector.keyCode
      else return ''
    },

    onCancel(clockIn) {
      this.dataSource = cloneDeep(this.sourceCopy)
      clockIn.edit = false
    },

    isSaveRowButtonDisabled(clockIn) {
      const isLunchTimeMissing = clockIn?.lunchTime === null
      if (isLunchTimeMissing && this.possibleQuestions.includes(4)) return true

      if (!clockIn.clockInTimeStamp || !this.rules.isTimeFormat(clockIn.clockInTimeStamp) || (clockIn.clockOutTimeStamp && !this.rules.isTimeFormat(clockIn.clockOutTimeStamp))) return true

      const missingProject = !clockIn?.sector?.keyCode ? true : false

      if (missingProject) return true

      const clockInHours = Number(clockIn.clockInTimeData.HH)
      const lateShift = clockInHours >= 18

      // if clockIn is larger than 18 return false to prevent any issues on the out
      if (lateShift) return false

      const clockOutHours = Number(clockIn.clockOutTimeData.HH)
      const clockInMinutes = Number(clockIn.clockInTimeData.mm)
      const clockOutMinutes = Number(clockIn.clockOutTimeData.mm)

      if (clockInHours === clockOutHours) {
        return clockOutMinutes < clockInMinutes
      }

      if (clockIn.confirmed) {
        return true
      }

      return false
    },

    async saveRow(row) {
      try {
        row.loading = true

        //SAVE/UPDATE IN - OUT
        row.clockInTimeData.HH = row.clockInTimeStamp[0] + row.clockInTimeStamp[1]
        row.clockInTimeData.mm = row.clockInTimeStamp[3] + row.clockInTimeStamp[4]
        //const clockInTime = Number(row.clockInTimeData.HH)

        const timeStampClockIn = moment.utc(row.clockIn ?? this.source.date)
        timeStampClockIn.set('hour', Number(row.clockInTimeData.HH))
        timeStampClockIn.set('minute', Number(row.clockInTimeData.mm))

        row.clockOutTimeData.HH = row.clockOutTimeStamp[0] + row.clockOutTimeStamp[1]
        row.clockOutTimeData.mm = row.clockOutTimeStamp[3] + row.clockOutTimeStamp[4]

        const timeStampClockOut = moment.utc(row.clockOut ?? this.source.date)

        if (row.clockOutTimeStamp) {
          timeStampClockOut.set('hour', Number(row.clockOutTimeData.HH))
          timeStampClockOut.set('minute', Number(row.clockOutTimeData.mm))
        }


        const clockInUpdateRequestObject = {
          id: row.id,
          clockInUpdatedTimestampStr: this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm'),
          clockOutUpdatedTimestampStr: row.clockOutTimeStamp ? this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm') : null,
          confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
          notes: row.notes,
          lunchTime: row.lunchTime
        }

        if (row.sector?.id) {
          clockInUpdateRequestObject.sector = { id: row.sector.id }
        }
        if (row.activity?.id) {
          clockInUpdateRequestObject.activity = { id: row.activity.id }
        }

        const res = await this.$apollo
          .mutate({
            mutation: updateOneInertClockInMutation,
            variables: {
              inertClockInUpdateRequestObject: clockInUpdateRequestObject
            }
          })
          .catch(error => {
            this.handleError(error)
          })

        row.hour = res.data.updateOneInertClockIn.hour
        row.clockIn = this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm')
        row.clockOut = row.clockOutTimeStamp ? this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm') : null
        row.edit = false
        this.forceUpdateLabel++
        row.loading = false

        if (this.order !== 'sector') {
          this.$emit('clockin-pair-updated', this.source.name, row)
          await this.updateWorkHours()
        } else {
          this.refetchProjectRow(this.dataSource.sector.id)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        row.loading = false
        this.showSuccess = false
        this.showError = true
      }
    },

    async confirmClockInPair(clockInPair, value) {
      clockInPair.confirmed = value
      await this.saveRow(clockInPair)
    },

    async confirmAllClockInPairs() {
      for (let ci of this.computedClockIns) {
        if (ci.id > 0) {
          await this.confirmClockInPair(ci, true)
        }
      }
    },

    openNewClockInHandler() {
      this.clockInDialog = true
    },

    startEditingClockIn(e, clockIn) {
      if (!clockIn.confirmed) {
        clockIn.edit = true
        clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn)

        if (clockIn.openMenu) {
          this.closeMenuHandler(clockIn)
        }
      }
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeMenuList === 'projects') {
          return this.$emit('fetch-more-projects', 'rowProjectsOption')
        } else {
          return this.$emit('fetch-more-categories', 'rowCategoryOptions')
        }
      }
    },

    inputHandler(value) {
      this.$emit('set-project-list-filter', { value, property: 'rowProjectsOption' })
      this.$emit('set-project-category-list-filter', { value, property: 'rowCategoryOptions' })
    },

    closeMenuHandler(clockIn) {
      clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn.clockIn)
      clockIn.openMenu = false
      this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOption' })
      this.$emit('set-project-category-list-filter', { value: '', property: 'rowCategoryOptions' })
    },

    formatEmployeeName(name) {
      let fullname = name.split(' ')
      return fullname[0] + ' ' + fullname[1].substring(0, 3) + '.'
    },

    refetchProjectRow(sectorId) {
      this.$emit('refetch-project-row', sectorId)
    },

    //dialog callback
    async saveNewClockInClockOut(clockInInfo) {
      this.newClockInLoader = true

      const newClockInRequest = clockInInfo.newClockInRequest

      const clockInTimeHH = Number(newClockInRequest.clockInTimestamp[0] + newClockInRequest.clockInTimestamp[1])
      const clockInTimemm = Number(newClockInRequest.clockInTimestamp[3] + newClockInRequest.clockInTimestamp[4])
      const clockOutTimeHH = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[0] + newClockInRequest.clockOutTimestamp[1]) : 0
      const clockOutTimemm = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[3] + newClockInRequest.clockOutTimestamp[4]) : 0

      const clockInTimestamp = moment.utc(this.source.date)
      clockInTimestamp.set('hour', Number(clockInTimeHH))
      clockInTimestamp.set('minute', Number(clockInTimemm))

      //add one day if clock out is happening the next day
      let dateOut = this.source.date
      if (clockOutTimeHH < clockInTimeHH) {
        dateOut = moment(this.source.date).add(1, 'days')
        dateOut = dateOut.format('YYYY-MM-DD')
      }

      const clockOutTimestamp = moment.utc(dateOut)
      clockOutTimestamp.set('hour', Number(clockOutTimeHH))
      clockOutTimestamp.set('minute', Number(clockOutTimemm))

      let employeeId = newClockInRequest.employee ? newClockInRequest.employee.id : this.source.id
      let sectorId = newClockInRequest.sector.id
      let activityId = newClockInRequest.activity ? newClockInRequest.activity.id : null


      const clockInAndOutRequest = {
        employeeId: employeeId,
        sectorId: sectorId,
        activityId: activityId,
        clockIn: this.momentDate(clockInTimestamp, 'YYYY-MM-DD HH:mm'),
        clockOut: newClockInRequest.clockOutTimestamp ? this.momentDate(clockOutTimestamp, 'YYYY-MM-DD HH:mm') : null,
        lunchTime: newClockInRequest.lunchTime,
        confirmed: this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED
      }

      const resInAndOut = await this.$apollo.mutate({
        mutation: saveInertClockInAdminRequestMutation,
        variables: { inertClockInRequest: clockInAndOutRequest }
      })

      const newInertClockIn = resInAndOut.data.saveInertClockInAdminRequest

      newInertClockIn.loading = false
      newInertClockIn.edit = false
      newInertClockIn.openMenu = false
      newInertClockIn.clockInTimeStamp = moment.utc(newInertClockIn?.clockIn).format('HH:mm')
      newInertClockIn.clockOutTimeStamp = moment.utc(newInertClockIn?.clockOut).format('HH:mm')
      newInertClockIn.clockInTimeData = {
        HH: moment.utc(newInertClockIn?.clockIn).format('HH'),
        mm: moment.utc(newInertClockIn?.clockIn).format('mm')
      }
      newInertClockIn.clockOutTimeData = {
        HH: moment.utc(newInertClockIn?.clockOut).format('HH'),
        mm: moment.utc(newInertClockIn?.clockOut).format('mm')
      }
      newInertClockIn.sector = newClockInRequest.sector
      newInertClockIn.activity = newClockInRequest.activity

      if (this.order === 'sector') {
        this.refetchProjectRow(this.dataSource.sector.id)
      } else {
        this.$emit('new-clockin-pair-saved', this.dataSource.name, newInertClockIn)
        await this.updateWorkHours()
      }

      //clear dialog project filter
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogSectorOptions' })
      this.$emit('set-employee-list-filter', { value: '', property: 'rowDialogEmployeeOptions' })

      this.newClockInLoader = false
      this.clockInDialog = false
    },

    async deleteClockOutFromClockInPair(clockInPair) {
      clockInPair.loading = true

      await this.$apollo
        .mutate({
          mutation: deleteInertClockOut,
          variables: { clockInId: clockInPair.id }
        })
        .catch(error => {
          this.handleError(error)
        })

      clockInPair.clockOut = null
      clockInPair.clockOutTimeStamp = null
      clockInPair.clockOutTimeData = {
        HH: '',
        mm: ''
      }
      clockInPair.hour = null

      if (this.order !== 'sector') {
        this.$emit('clockin-pair-updated', this.source.name, clockInPair)
        await this.updateWorkHours()
      } else {
        this.refetchProjectRow(clockInPair.sector.id)
      }

      clockInPair.loading = false
      clockInPair.edit = false
    },

    async updateWorkHours() {
      const date = this.sourceCopy.date
      const employeeId = this.sourceCopy.id
      const res = await this.$apollo.query({
        query: updateInertWorkHours,
        variables: {
          dateStr: date,
          employeeId: employeeId
        },
        fetchPolicy: 'network-only'
      })
      const workHours = res.data.updateInertWorkHours
      this.sourceCopy.workHours = workHours
    }
  },

  computed: {
    ...mapState({
      clockInsDate: state => state.clockIns.clockInsDate
    }),

    icons: () => ({
      mdiChevronRight,
      mdiChevronLeft,
      mdiSquareEditOutline,
      mdiContentSaveOutline,
      mdiCancel,
      mdiClose,
      mdiPlus,
      mdiTrashCanOutline,
      mdiCheckCircleOutline,
      mdiCheck,
      mdiWeatherNight
    }),

    translations: () => translations,

    defaultProject() {
      return this.dataSource?.clockIns?.[0]?.sectorKeyCode || ''
    },

    computedClockIns() {
      if (!this.dataSource?.clockIns?.length) {
        return []
      } else if (this.dataSource?.clockIns.length && this.order !== 'sector') {
        return this.dataSource.clockIns.filter(clockIn => clockIn.id > 0)
      } else {
        return this.dataSource.clockIns
      }
    },

    hasLunchTime() {
      const hasTime = e => e.lunchTime !== null && e.lunchTime >= 0

      return this.orderBy !== 'projectCategory' ? this.computedClockIns.some(hasTime) : false
    },

    allClockInsAreConfirmed() {
      return this.computedClockIns.every(ci => ci.confirmed == true)
    },

    lunchBreakOptions() {
      return getLunchTimes()
    },

    resetAllowanceOnWeekend() {
      return getResetAllowanceOnWeekend()
    },

    showClockInTimeAlert() {
      return getShowClockInTimeAlert()
    },

    dateIsOnWeekend() {
      return this.selectedDate ? [0, 6].includes(this.momentToDate(this.selectedDate).getDay()) : false
    },

    mainColumn() {
      return this.orderBy === 'employee' ? this.dataSource.name : this.dataSource.sector?.keyCode
    },

    missingTime() {
      // if no selectedClockInPair or dialogType or property is false do not persist
      if (Object.keys(this.selectedClockInPair).length <= 0 || this.dialogType === '' || !this.selectedClockInPair[this.dialogType]) return false
      return this.selectedClockInPair[this.dialogTypeHours] <= 0
    }
  }
}
</script>


<style>
.virt-grid-row {
  display: grid;
  grid-template-columns: 200px 10fr;
  position: relative;
}

.report-row .v-text-field .v-input__control .v-input__slot {
  min-height: 25px !important;
  display: flex !important;
  align-items: center !important;
}

.report-row .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 25px !important;
}

.report-row .v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 13px;
}

.clockin-row {
  align-items: center;
  display: grid;
}

.clockin-mid-grid-row {
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  display: grid;
}

.clockin-row > * {
  align-items: center;
  text-align: -webkit-center;
  height: 35px;
  padding-top: 5px;
  padding-left: 1px;
}

.clockin-row:hover {
  background-color: #f4f8ff;
}

div.layoff > div.v-input {
  margin: 0;
}

.wb-w100 {
  word-wrap: break-word;
  width: 170px;
}

.f-12 {
  font-size: 12px;
}

.v-input.v-input--hide-details.v-input--dense.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 0;
}

.notes-text {
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  max-width: 120px;
}

/* part select styling  */
.report-row .part-select .v-input__control .v-input__slot {
  padding: 0px 8px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  flex-wrap: nowrap !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner {
  margin-top: 12px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
  font-size: 14px;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon {
  height: 18px !important;
  width: 18px !important;
  max-width: 18px !important;
  min-width: 18px !important;
}

.security-type-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
}

.security-type-container .checkbox {
  margin: 0;
  padding: 0;
}

.textarea {
  margin: 0;
  padding: 10px 0;
}

/* end part select styling  */

.clock-in-notes .v-input__slot {
  max-height: 30px !important;
}

.clock-in-notes textarea {
  width: 120px !important;
  max-height: 30px !important;
  font-size: 10px !important;
  line-height: 16px !important;
  resize: none !important;
  border-radius: 5px;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
